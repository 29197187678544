import SortableTable from '../../shared/SortableTable'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DATE_FORMAT } from '../../../helper/constants'
import { useTranslation } from 'react-i18next'
import 'moment/locale/fi'
import MonthYearRangePicker from '../../shared/MonthYearRangePicker'
import {
  filterByDateRange,
  getMaxDate,
  getMinDate,
} from '../../../helper/dates'
import { useQualityPageContext } from '../../../contextproviders/QualityPageContext'
import { TrafficLight } from '../../shared/trafficLight'
import { Checkbox } from '../../shared/checkbox'
import { filterByRating } from '../../../helper/filters'

function QualityTable() {
  const { t } = useTranslation()
  const {
    quality: { data },
  } = useQualityPageContext()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())
  useEffect(() => {
    if (data.length > 0) {
      const dates = data.map((data) => new Date(data.date))
      setMinDate(getMinDate(dates))
      setMaxDate(getMaxDate(dates))
      setStartDate(getMinDate(dates))
      setEndDate(getMaxDate(dates))
    }
  }, [data])
  const tableColumns = [
    {
      Header: t('Date short'),
      accessor: 'date',
      disableFilters: true,
      Cell: ({ value }) => moment(value).format(DATE_FORMAT),
    },
    {
      Header: t('Site'),
      accessor: 'site.address.street_name',
    },
    {
      Header: t('City'),
      accessor: 'site.address.city',
    },
    {
      Header: t('Report type'),
      accessor: 'reportType',
    },
    {
      Header: t('Quality rating'),
      accessor: 'rating',
      disableFilters: true,
      Cell: ({
        value,
        row: {
          original: { isError, isWarning, reportType },
        },
      }) => getTrafficLight(value, reportType, isError, isWarning),
    },
    {
      Header: t('Report'),
      accessor: 'pdfUrl',
      disableFilters: true,
      Cell: ({ value }) => getReport(value),
    },
  ]

  const getReport = (value) => {
    if (!value) return null

    return (
      <span className="report">
        <a href={value} download={value} target="_blank" rel="noreferrer">
          <span className="report">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#003087" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8439 11.7189C13.3042 11.2586 13.9286 11 14.5795 11H21.125C21.342 11 21.5501 11.0862 21.7035 11.2396L26.6126 16.1487C26.7661 16.3022 26.8523 16.5103 26.8523 16.7273V26.5455C26.8523 27.1964 26.5937 27.8208 26.1334 28.2811C25.673 28.7414 25.0487 29 24.3977 29H14.5795C13.9286 29 13.3042 28.7414 12.8439 28.2811C12.3836 27.8208 12.125 27.1964 12.125 26.5455V13.4545C12.125 12.8036 12.3836 12.1792 12.8439 11.7189ZM14.5795 12.6364C14.3626 12.6364 14.1544 12.7226 14.001 12.876C13.8476 13.0294 13.7614 13.2376 13.7614 13.4545V26.5455C13.7614 26.7625 13.8476 26.9706 14.001 27.124C14.1544 27.2774 14.3626 27.3636 14.5795 27.3636H24.3977C24.6147 27.3636 24.8228 27.2774 24.9763 27.124C25.1297 26.9706 25.2159 26.7624 25.2159 26.5455V17.0662L20.7861 12.6364H14.5795Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.125 11C21.5769 11 21.9432 11.3663 21.9432 11.8182V15.9091H26.0341C26.486 15.9091 26.8523 16.2754 26.8523 16.7273C26.8523 17.1791 26.486 17.5455 26.0341 17.5455H21.125C20.6731 17.5455 20.3068 17.1791 20.3068 16.7273V11.8182C20.3068 11.3663 20.6731 11 21.125 11Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3977 20.8182C15.3977 20.3663 15.764 20 16.2159 20H22.7614C23.2132 20 23.5795 20.3663 23.5795 20.8182C23.5795 21.2701 23.2132 21.6364 22.7614 21.6364H16.2159C15.764 21.6364 15.3977 21.2701 15.3977 20.8182Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3977 24.0909C15.3977 23.639 15.764 23.2727 16.2159 23.2727H22.7614C23.2132 23.2727 23.5795 23.639 23.5795 24.0909C23.5795 24.5428 23.2132 24.9091 22.7614 24.9091H16.2159C15.764 24.9091 15.3977 24.5428 15.3977 24.0909Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3977 17.5455C15.3977 17.0936 15.764 16.7273 16.2159 16.7273H17.8523C18.3041 16.7273 18.6705 17.0936 18.6705 17.5455C18.6705 17.9973 18.3041 18.3636 17.8523 18.3636H16.2159C15.764 18.3636 15.3977 17.9973 15.3977 17.5455Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </span>
    )
  }
  const getTrafficLight = (value, category, isError, isWarning) => {
    if (value === undefined || value === null) return null
    const getFormattedValue = (category, value) => {
      switch (category) {
        case 'Huoltoraportti':
          return null
        case 'Pintahygienia':
          return (
            <span className="rating">
              {value % 1 === 0
                ? value
                : Number(value?.toFixed(1)).toLocaleString('fi-FI')}
            </span>
          )
        default:
          return (
            <span className="rating">
              {Number(value?.toFixed(2)).toLocaleString('fi-FI', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          )
      }
    }
    switch (category) {
      case 'Huoltoraportti':
        return (
          <span className="rating">
            <TrafficLight warning={isWarning} error={isError} gray={true} />
            {getFormattedValue(category, value)}
          </span>
        )
      default:
        return (
          <span className="rating">
            <TrafficLight warning={isWarning} error={isError} />
            {getFormattedValue(category, value)}
          </span>
        )
    }
  }

  const [filter, setFilter] = useState(false)

  return (
    <>
      <nav className="level">
        <div className="level-left table-title">
          <div className="level-item">
            <h2 className="title is-size-4">{t('Quality by site')}</h2>
          </div>

          <div className="level-item">
            <MonthYearRangePicker
              startDate={startDate}
              startDateOnChange={(date) => setStartDate(date)}
              endDate={endDate}
              endDateOnChange={(date) => setEndDate(date)}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>

          <div>
            <Checkbox
              label={t('Show only exceptions')}
              checked={filter}
              onChange={() => setFilter(!filter)}
            />
          </div>
        </div>
      </nav>
      <div>
        <div className="level-left">
          <div className="level-item">
            <h2 className="is-size-6">
              {
                'Kaikki raporttiliitteet eivät ole tällä hetkellä saatavilla, vaan ne tuodaan portaaliin vaiheittain.'
              }
            </h2>
          </div>
        </div>
      </div>
      <br />

      <SortableTable
        columns={tableColumns}
        data={filterByRating(
          filterByDateRange(data, startDate, endDate),
          filter
        )}
        showBottomRowStats={false}
      />
    </>
  )
}

export default QualityTable
